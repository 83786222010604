
















import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem
    }
})
export default class Contact extends Vue {
    get img_src() {
        const url_base =
            "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/";
        let url =
            this.$i18n.locale == "en"
                ? `${url_base}bestech_2020_lightpoint_en.png`
                : `${url_base}bestech_2020_lightpoint_zh.png`;
        return url;
    }
}
