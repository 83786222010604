


























import { Vue, Component } from "vue-property-decorator";
import PageTitle from "@/components/home/first/FirstActivityTitle.vue";
import ReviewVideo from "@/components/home/first/ReviewVideo.vue";
import AchieveShow from "@/components/home/first/AchieveShow.vue";
import ActivitySite from "@/components/home/first/ActivitySite.vue";

@Component({
    components: {
        "page-title": PageTitle,
        "review-video": ReviewVideo,
        achieveshow: AchieveShow,
        activitysite: ActivitySite
    }
})
export default class FirstActivity extends Vue {}
