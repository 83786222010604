

















import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem
    }
})
export default class ActivitySite extends Vue {
    get picArr() {
        let opt: {}[] = [
            {
                url:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/2020%E7%8E%B0%E5%9C%BA%E5%9B%BE%E7%89%87/1.jpg"
            },
            // {
            //     url:
            //         "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/2020%E7%8E%B0%E5%9C%BA%E5%9B%BE%E7%89%87/2.jpg"
            // },
            {
                url:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/2020%E7%8E%B0%E5%9C%BA%E5%9B%BE%E7%89%87/3.jpg"
            },
            {
                url:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/2020%E7%8E%B0%E5%9C%BA%E5%9B%BE%E7%89%87/4.jpg"
            },
            {
                url:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/2020%E7%8E%B0%E5%9C%BA%E5%9B%BE%E7%89%87/5.jpg"
            },
            // {
            //     url:
            //         "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/2020%E7%8E%B0%E5%9C%BA%E5%9B%BE%E7%89%87/6.jpg"
            // },
            {
                url:"https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/2020%E7%8E%B0%E5%9C%BA%E5%9B%BE%E7%89%87/7.jpg"
            },
            {
                url:"https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/2020%E7%8E%B0%E5%9C%BA%E5%9B%BE%E7%89%87/8.jpg"
            }
        ];
        return opt;
    }
}
